.plateviewer {
  padding: 70px;
  margin-left: auto;
  margin-right: auto;
}
.pcb-viewer {
  position: relative;
  padding: 70px;
  margin-left: auto;
  margin-right: auto;
  background-color: antiquewhite;
  height: 500px;
  width: 1200px;
  border-radius: 10px;
}
.configuration {
  height: 500px;
  padding: 70px;
  margin-left: auto;
  margin-right: auto;
}
.key{
  position: absolute;
  margin: 5px;
  padding: 5px;
  border-color: antiquewhite;
  border-style: solid;
  border-radius: 10px;
}

.dot {
  height: 10px;
  width: 10px;
  background-color: rgb(0, 0, 0);
  border-radius: 100%;
  position: absolute;

}




.row {
  position: absolute;
  bottom: 10px;
  left: 10px;
  width: 300px;
  align-content: center;
  columns: 6;
}
.pcb-download {
  position: absolute;
  bottom: 15px;
  right: 10px;
}

.row input {
  width:40px;
}

select {
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
}

textarea {
  font-family: 'Fira Code', 'Menlo', 'Courier New', Courier, monospace;
}
